import { AuthenticationService } from "./authentication/service/AuthenticationService";
import env from "./env";

export const extendSession = (activityContext) => {
  const service = new AuthenticationService(env.API ?? "");
  if (!service || !activityContext) {
    return;
  }
  service
    .getUserData()
    .then(() => {
      activityContext.setLastUserActivityTimeStamp(new Date());
      activityContext.setLoginTimeStamp(new Date());
    })
    .catch((error) => {
      console.error(error);
      window.location.href = "/";
    });
};
